import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

import Layout from '../layout'
import NoHeaderLayout from '../layout/index2'
import ExpertLayout2 from '../layout/expertIndex'

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('../views/home/index'),
        name: 'HomePage',
        meta: { title: '首页' }
      }
    ]
  },
  {
    path: '/login',
    component: NoHeaderLayout,
    children: [
      {
        path: '',
        component: () => import('../views/login/index'),
        name: 'LoginPage',
        meta: { title: '用户登录' }
      }
    ]
  },
  {
    path: '/register',
    component: NoHeaderLayout,
    children: [
      {
        path: '',
        component: () => import('../views/login/register'),
        name: 'RegisterPage',
        meta: { title: '用户注册' }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('../views/user/index'),
        name: 'UserInfoWrapper',
        meta: { title: '个人中心' },
        children: [
          {
            path: 'info',
            component: () => import('../views/user/components/BasicInfo'),
            name: 'UserInfo',
            meta: { title: '我的信息' },
          },
          {
            path: 'upload',
            component: () => import('../views/user/components/MyUpload'),
            name: 'UserUpload',
            meta: { title: '我的上传' },
          }
        ]
      }
    ]
  },
  {
    path: '/upload',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "upload" */'../views/upload/index'),
        name: 'UploadDetailPage',
        meta: { title: '数据详情' }
      },
      {
        path: 'new',
        component: () => import(/* webpackChunkName: "upload" */'../views/upload/upload-create'),
        name: 'UploadCreatePage',
        meta: { title: '数据上传' }
      },
      {
        path: 'edit',
        component: () => import(/* webpackChunkName: "upload" */'../views/upload/upload-create'),
        name: 'UploadEditPage',
        meta: { title: '数据修改' }
      }
    ]
  },
  {
    path: '/art',
    name: 'Art',
    component: Layout,
    children: [
      {
        path: 'detail',
        component: () => import('../views/art/detail'),
        name: 'HomeArtDetail',
        meta: { title: '数据详情' }
      }
    ]
  },
  {
    path: '/file',
    name: 'File',
    component: Layout,
    children: [
      {
        path: 'detail',
        component: () => import('../views/art/file-detail'),
        name: 'FileDetailComp',
        meta: { title: '文件详情' }
      }
    ]
  },
  {
    path: '/expert',
    component: ExpertLayout2,
    children: [
      {
        path: 'review',
        component: () => import(/* webpackChunkName: "expert" */'../views/expert-review/index.vue'),
        name: 'ExpertReview',
        meta: { title: '数据评审列表', icon: 'dashboard' }
      },
      {
        path: 'review/detail',
        component: () => import(/* webpackChunkName: "expert" */'../views/expert-review/review-detail.vue'),
        name: 'ExpertReviewDetail',
        meta: { title: '数据详情', icon: 'dashboard' }
      }
    ]
  },
  {
    path: '/expert/login',
    component: NoHeaderLayout,
    children: [
      {
        path: '',
        component: () => import('../views/expert-login/index.vue'),
        name: 'ExpertLoginPage',
        meta: { title: '专家登录' }
      }
    ]
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
