<template>
  <div class="body">
    <div class="main-content">
      <router-view></router-view>
    </div>
    <y-footer></y-footer>
  </div>
</template>

<script>
  import YFooter from "@/page-home/layout/components/YFooter";
  export default {
    name: "Layout2",
    components: {YFooter}
  }
</script>

<style lang="scss" scoped>
  .body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 1200px;
    display: table;
    table-layout: fixed;
    background-color: #ffffff;
  }
  .main-content {
    display: table-row;
    height: 100%;
  }
</style>
