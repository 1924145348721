import axios from 'axios'
import { Loading, Message } from '@/library/element-ui/lib'
import {getToken, removeAllToken, setToken} from "@/page-home/utils/storage";
import {refreshUserToken} from "@/page-home/api/auth";
import router from '../router'
import store from '../store'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

let isRefreshing = false
let requests = []

axiosInstance.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      //  给data赋值以绕过if判断,解决get没有设置Content-Type
      config.data = true
    }
    const token = getToken('access') || ''
    config.headers['X-Token'] = token
    return config
  },
  error => {
    Message({
      type: 'error',
      message: '发送请求失败，请重试'
    })
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => {
    let res = response.data
    if (res.code === 0) {
      return res
    } else if (res.code === 1) {
      // 尝试刷新token
      const config = response.config
      if (!isRefreshing) {
        isRefreshing = true
        return handleRefreshToken().then(data => {
          requests.forEach(cb => cb(data.accessToken))
          requests = []
          isRefreshing = false
          return axiosInstance.request(config)
        }).catch(err => {
          console.log(err);
          console.log('opps');
          Message({
            type: 'error',
            message: '请重新登录'
          })
          removeAllToken()
          store.commit('SET_USER_INFO', {})
          router.replace('/')
          return Promise.reject('error')
        })
      } else {
        // 正在登录，其他请求存起来
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((token) => {
            config.headers['X-Token'] = token
            resolve(axiosInstance.request(config))
          })
        })
      }
      return Promise.reject('error')
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    if (error.response) {
      const status = error.response.status
      const tips = status in httpCode ? httpCode[status] : error.response.data.message
      Message({
        message: tips,
        type: 'error'
      })
      return Promise.reject(error)
    } else {
      Message({
        message: '请求超时, 请刷新重试',
        type: 'error'
      })
      return Promise.reject(new Error('请求超时, 请刷新重试'))
    }
  }
)

const httpCode = {
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时'
}

const handleRefreshToken = () => {
  const refreshToken = getToken('refresh')
  const accessToken = getToken('access')
  if (!refreshToken || !accessToken) {
    return Promise.reject('请重新登录')
  }
  const payload = {
    // accessToken: accessToken.split('-')[1], 由后端分割判断类型
    accessToken: accessToken,
    refreshToken
  }
  return refreshUserToken(payload).then(res => {
    removeAllToken()
    setToken('access', 'N-' + res.data.accessToken)
    setToken('refresh', res.data.refreshToken)
    return res
  })
}

export default axiosInstance
