import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  state: {
    userInfo: {
      // uid: 1
    },
    selCateId: 0,
    selFileDetail: {},

    userUploadActiveTab: 'first'
  },
  mutations: {
    SET_USER_INFO: (state, info) => {
      state.userInfo = info
    },
    SET_USER_INFO_ITEM: (state, data) => {
      state.userInfo[data.itemName] = data.itemValue
    },
    SET_SEL_CATE_ID: (state, data) => {
      state.selCateId = data
    },

    SET_SEL_FILE_DETAIL: (state, payload) => {
      state.selFileDetail = payload
    },

    SET_USERUPLOAD_ACTIVE_TAB: (state, payload) => {
      state.userUploadActiveTab = payload
    },
  },
  actions: {
  },
  modules: {
  },

  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
