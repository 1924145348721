<template>
  <footer>
    <div class="copyright">
      <div class="item">Copyright © 版权所有</div>
      <div class="item">
        运营单位：xxxxx有限公司 &nbsp;
        备案号：京ICP备xxxxxx号
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "YFooter"
  }
</script>

<style scoped>
  .copyright {
    height: 60px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    color: #ffffff;
    background: #28292B;
  }
</style>
