<template>
  <div class="body">
    <y-header></y-header>
    <div class="main-content">
      <router-view></router-view>
    </div>
    <y-footer></y-footer>
    <!--<el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>-->
  </div>
</template>

<script>
  import YHeader from "@/page-home/layout/components/YHeader";
  import YFooter from "@/page-home/layout/components/YFooter";
  export default {
    name: "Layout",
    components: {YFooter, YHeader}
  }
</script>

<style lang="scss" scoped>
  .body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 1200px;
    display: table;
    table-layout: fixed;
    background-color: #F2F4FA;
  }
  .main-content {
    display: table-row;
    height: 100%;
  }
</style>
