<template>
  <div class="login-wrapper">
    <div class="login-bg">
      <div class="login-cloud cloud1"></div>
      <div class="login-cloud cloud2"></div>
      <div class="login-logo">Welcome!</div>
    </div>
    <div class="login-form">
      <el-form v-if="loginType === 1" ref="loginForm" :model="loginForm" :rules="rules">
        <el-form-item prop="username">
          <el-input
                  v-model="loginForm.username"
                  placeholder="手机号/用户名"
                  name="username"
                  type="text"
                  tabindex="1"
                  autocomplete="on" maxlength="11"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
                  v-model="loginForm.password"
                  placeholder="请输入密码"
                  name="password"
                  type="password"
                  tabindex="1"
                  autocomplete="on" maxlength="20"
          />
        </el-form-item>
      </el-form>
      <el-form v-if="loginType === 2" ref="loginForm" :model="loginForm" :rules="rules">
        <el-form-item prop="phone">
          <el-input
                  v-model="loginForm.phone"
                  placeholder="请输入手机号"
                  name="phone"
                  type="text"
                  tabindex="1"
                  autocomplete="on" maxlength="11"
          />
        </el-form-item>
        <el-form-item prop="code">
          <el-input
                  v-model="loginForm.code"
                  placeholder="短信验证码"
                  name="code"
                  type="text"
                  tabindex="2"
                  autocomplete="on" maxlength="6">
            <template slot="append">
              <el-button type="success" style="width: 120px;"
                         @click="getLoginPhoneCode">{{codeBtnText}}</el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="width: 100%;" :loading="loading"
                 @click="handleSubmit">登录</el-button>
      <div class="login-type-change">
        <span style="color: #666;">其他方式：</span>
        <span class="login-type-text" @click="handleLoginTypeChange">{{loginTypeText}}</span>
      </div>
      <div class="login-type-change">
        <span style="color: #666;">暂无账号，</span>
        <span class="login-type-text" @click="toRegisterPage">立即注册</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {sendSmsCode, userLogin} from "@/page-home/api/auth";
  import {setToken} from "@/page-home/utils/storage";
  import {getUserDetail} from "@/page-home/api/home";
  import {isMobile} from "@/page-home/utils/validate";

  const CODE_TIME_COUNT = 60
  export default {
    name: "LoginDialog",
    data() {
      const validPhone = (rule, value, callback) => {
        if (isMobile(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
      return {
        loading: false,
        loginForm: {},
        loginType: 1, // 1账号密码，2短信

        codeCount: CODE_TIME_COUNT,
        codeBtnText: '获取验证码',
        codeBtnDisabled: false,
        timer: null,

        rules: {
          username: [
            {required: true, message: '请输入用户名', trigger: 'blur'},
          ],
          password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
          ],
          phone: [
            {required: true, message: '请输入中国大陆手机号', trigger: 'blur'},
            {validator: validPhone, trigger: 'blur'}
          ],
          code: [
            {required: true, message: '请输入验证码', trigger: 'blur'},
            {min: 6, message: '请输入正确的验证码', trigger: 'blur'}
          ],
        }
      }
    },

    computed: {
      loginTypeText() {
        if (this.loginType === 1) {
          return '短信验证码登录'
        }
        if (this.loginType === 2) {
          return '密码登录'
        }
        return ''
      }
    },

    methods: {
      handleLoginTypeChange() {
        this.$refs['loginForm'].resetFields()
        this.loginType = (this.loginType-1)===0 ? 2 : 1
        this.loginForm = {}
        this.codeBtnText = '获取验证码'
      },

      getLoginPhoneCode() {
        if (!isMobile(this.loginForm.phone)) {
          this.$message.error('请检查手机号是否输入正确')
          return
        }
        if (this.codeBtnDisabled) return
        this.codeBtnDisabled = true
        sendSmsCode({phone: this.loginForm.phone, type: 1}).then(res => {
          this.$message.success('验证码发送成功')
          this.codeBtnText = this.codeCount + ' s'
          this.timer = setInterval(() => {
            if (this.codeCount > 0 && this.codeCount <= CODE_TIME_COUNT) {
              this.codeCount--
              this.codeBtnText = this.codeCount + ' s'
            } else {
              this.codeCount = CODE_TIME_COUNT
              clearInterval(this.timer)
              this.codeBtnText = "重新获取"
              this.timer = null
              this.codeBtnDisabled = false
            }
          }, 1000)
        }).catch(err => {
          this.codeBtnDisabled = false
          this.$message.error(err.msg)
        })
      },

      handleSubmit() {
        if (this.loading) return
        this.$refs['loginForm'].validate(valid => {
          if (valid) {
            this.loading = true
            const payload = {
              loginType: this.loginType,
              phone: this.loginForm.phone,
              code: this.loginForm.code,
              username: this.loginForm.username,
              password: this.loginForm.password
            }
            userLogin(payload).then(res => {
              setToken('access', 'N-' + res.data.accessToken)
              setToken('refresh', res.data.refreshToken)
              getUserDetail().then(res => {
                this.$store.commit('SET_USER_INFO', res.data)
                this.$message.success('登录成功')
                this.loginType = 1
                setTimeout(() => {
                  this.loginForm = {}
                  this.$router.replace('/')
                }, 300)
              })
            }).catch(err => {
              this.loginForm.code = ''
              this.loginForm.password = ''
              this.$message.error(err.msg)
            }).finally(() => {
              this.loading = false
            })
          } else {
            return
          }
        })
      },

      toRegisterPage() {
        this.$router.replace('/register')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login-wrapper {
    width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    .login-bg {
      width: 100%;
      height: 158px;
      background-image: url(./images/login-bg.png);
      overflow: hidden;
    }
    .login-form {
      padding: 20px;
    }
  }
  .login-logo {
    margin-top: 30px;
    font-size: 40px;
    text-align: center;
    color: #1fcab3;
    font-weight: bold;
  }

  .login-type-change {
    margin-top: 20px;
    color: #3a8ee6;
    .login-type-text {
      display: inline-block;
      cursor: pointer;
    }
  }
</style>

<style lang="scss">
  .el-input-group__append {
    width: 160px;
    text-align: center;
  }
</style>
