const ACCESS_TOKEN_KEY = 'USER_ACCESS_TOKEN'
const REFRESH_TOKEN_KEY = 'USER_REFRESH_TOKEN'

const EXPERT_ACCESS_TOKEN_KEY = 'EXPERT_ACCESS_TOKEN'
const EXPERT_REFRESH_TOKEN_KEY = 'EXPERT_REFRESH_TOKEN'

export function getToken(type) {
  if (type === 'access') {
    return sessionStorage.getItem(ACCESS_TOKEN_KEY)
  }
  if (type === 'refresh') {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY)
  }
}

export function setToken(type, token) {
  if (type === 'access') {
    return sessionStorage.setItem(ACCESS_TOKEN_KEY, token)
  }
  if (type === 'refresh') {
    return sessionStorage.setItem(REFRESH_TOKEN_KEY, token)
  }
}

export function removeAllToken() {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(REFRESH_TOKEN_KEY)
}

export function getTokenE(type) {
  if (type === 'access') {
    return sessionStorage.getItem(EXPERT_ACCESS_TOKEN_KEY)
  }
  if (type === 'refresh') {
    return sessionStorage.getItem(EXPERT_REFRESH_TOKEN_KEY)
  }
}

export function setTokenE(type, token) {
  if (type === 'access') {
    return sessionStorage.setItem(EXPERT_ACCESS_TOKEN_KEY, token)
  }
  if (type === 'refresh') {
    return sessionStorage.setItem(EXPERT_REFRESH_TOKEN_KEY, token)
  }
}

export function removeAllTokenE() {
  sessionStorage.removeItem(EXPERT_ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(EXPERT_REFRESH_TOKEN_KEY)
}
