<template>
  <header class="header">
    <div class="logo" @click="toHomePage">
      <img class="img-logo" alt="传统知识数据平台" src="@/assets/img/logo.png" />
      传统知识数据平台
    </div>
    <div class="header-title" v-if="!showCateList">
      {{pageName}}
    </div>
    <ul class="cate-list" v-if="showCateList">
      <li class="cate-item" :class="{'cate-item-active' : selCateId === item.id}"
          v-for="item in cates" :key="item.id"
          @click="handleCateSelect(item)">{{item.name}}</li>
    </ul>
    <div v-if="userInfo.uid" class="user-info-wrapper">
      <el-dropdown class="m-r-15 hover-effect" trigger="click">
        <div class="user-info">
          <!--<el-avatar size="medium"></el-avatar>-->
          <span>{{userInfo.phone | formatPhoneNum}}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item divided @click.native="toPersonPage">
            <span style="display:block;">个人主页</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="handleUserLogout">
            <span style="display:block;">退出登陆</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button v-if="showCateList"
                 class="btn-register" type="primary" @click="toUploadPage">上传</el-button>
    </div>

    <div v-else class="btn-group">
      <el-button class="btn-login" type="text" @click="handleLoginClick">登录</el-button>
      <el-button class="btn-register" type="primary" @click="toRegisterPage">注册</el-button>
    </div>

    <el-dialog class="dialog-login" v-if="loginDialogVisible" :visible.sync="loginDialogVisible"
               :close-on-click-modal="false">
      <login-dialog @close="loginDialogVisible = false"></login-dialog>
    </el-dialog>
  </header>
</template>

<script>
  import LoginDialog from "@/page-home/components/Login/index";
  import {mapState} from 'vuex'
  import {getAllCategory, getUserDetail} from "@/page-home/api/home";
  import {userLogout} from "@/page-home/api/auth";
  import {removeAllToken} from "@/page-home/utils/storage";
  export default {
    name: "YHeader",
    components: {LoginDialog},
    data() {
      return {
        loginDialogVisible: false,
        showCateList: false,
        cates: [],
        pageName: ''
      }
    },

    computed: {
      ...mapState({
        userInfo: state => state.userInfo,
        selCateId: state => state.selCateId
      })
    },

    watch: {
      '$route' (to, from) {
        this.pageName = to.meta.title
        if (to.path === '/') {
          this.showCateList = true
        } else {
          this.showCateList = false
        }
      }
    },

    filters: {
      formatPhoneNum(value) {
        if (value) {
          return value.substring(0,3) + '****' + value.substring(value.length-4)
        } else {
          return ''
        }
      }
    },

    methods: {
      handleLoginClick() {
        // this.loginDialogVisible = true
        this.$router.push('/login')
      },

      toRegisterPage() {
        this.$router.push('/register')
      },

      toPersonPage() {
        this.$router.push('/user/info')
      },
      toUploadPage() {
        this.$router.push('/user/upload')
      },
      toHomePage() {
        this.$router.replace('/').catch(err => err)
      },

      handleUserLogout() {
        userLogout()
        this.$store.commit('SET_USER_INFO', {})
        removeAllToken()
        this.$router.replace('/')
      },

      getAllCategoryFromApi() {
        getAllCategory().then(res => {
          this.cates = res.data || []
          this.cates.unshift({id: 0, name: '全部'})
        })
      },

      handleCateSelect(item) {
        if (item.id === this.selCateId) return
        this.$store.commit('SET_SEL_CATE_ID', item.id)
      }
    },

    created() {
      console.log('header create');
      // if (!this.userInfo.uid) {
      //   getUserDetail().then(res => {
      //     this.$store.commit('SET_USER_INFO', res.data)
      //   })
      // }
      if (this.$route.path === '/') this.showCateList = true
      this.pageName = this.$route.meta.title
      this.getAllCategoryFromApi()
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid #f1f1f1;
    background-color: #ffffff;
    .logo {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      padding: 20px 0;
      cursor: pointer;
      .img-logo {
        width: 26px;
        height: 26px;
        margin-right: 10px;
      }
    }
    .header-title {
      margin-left: 32px;
      font-size: 18px;
      color: #004DF8;
    }
  }
  .btn-group {
    max-width: 160px;
    margin-left: auto;
  }
  .btn-login {
    margin-right: 20px;
    font-size: 18px;
    color: #ABAAB1;
  }
  .btn-register {
    width: 88px;
    height: 44px;
    font-size: 18px;
    background-color: #004DF8;
    border-color: #004DF8;
  }

  .user-info-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
    padding: 0 8px;
    font-size: 18px;
    color: #5a5e66;
    .hover-effect {
      cursor: pointer;
      transition: background .3s;
      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }
  }

  .cate-list {
    display: flex;
    flex-wrap: nowrap;
    max-width: 800px;
    margin-left: 50px;
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    font-size: 18px;
    font-weight: 500;
    color: #ABAAB1;
    list-style-type:none;
    .cate-item {
      flex-shrink: 0;
      margin-left: 40px;
      padding: 8px 14px;
      white-space: nowrap;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &-active {
        color: #000000;
        background-color: #F1F1F2;
      }
    }
  }
</style>

<style lang="scss">
  .dialog-login {
    .el-dialog {
      width: 400px;
    }
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .el-dropdown-menu {
    .el-dropdown-menu__item--divided {
      margin-top: 0;
    }
    /*.el-dropdown-menu__item {*/
    /*  line-height: 26px;*/
    /*}*/
  }
</style>
