import axios from './axios'

export function addNewArt() {
  return axios.post('/api/art/create')
}

// 初始化上传
export function initUpload(payload) {
  return axios.post('/api/art/init', payload)
}

// 获取sts token
export function getOssToken(payload) {
  return axios.post('/api/upload/oss/token', payload)
};

export function getUserDetail() {
  return axios.post('/api/user/info/detail')
}

export function getAllCategory() {
  return axios.post('/api/public/cate/list')
}

export function getArtsByPage(payload) {
  return axios.post('/api/public/art/list/home', payload)
}

export function getUserArtsByPage(payload) {
  return axios.post('/api/art/list/user', payload)
}

export function getUserArtDetailByArtId(payload) {
  return axios.post('/api/art/detail', payload)
}
export function getHomeArtDetailByArtId(payload) {
  return axios.post('/api/public/art/detail', payload)
}

export function saveArt(payload) {
  return axios.post('/api/art/save', payload)
}

export function submitArt(payload) {
  return axios.post('/api/art/submit', payload)
}

// 数据文件上传成功时，保存
export function saveArtFile(payload) {
  return axios.post('/api/art/file/save', payload)
}

export function deleteArtFile(payload) {
  return axios.post('/api/art/file/delete', payload)
}

export function updateArtFile(payload) {
  return axios.post('/api/art/file/update', payload)
}

export function uploadImg(payload) {
  const headers = {
    'Content-Type': 'multipart/form-data;charset=UTF-8'
  }
  return axios.post('/api/upload/image', payload, {headers})
}

export function updateUserInfo(payload) {
  return axios.post('/api/user/info/update', payload)
}

export function resetUserPwd(payload) {
  return axios.post('/api/user/resetPwd', payload)
}
