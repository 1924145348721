import router from './router'
import store from './store'
import { Message } from '@/library/element-ui/lib'
import {getToken} from "@/page-home/utils/storage";

const whiteList = ['/login', '/expert/login', '/register', '/', '/art/detail'] // no redirect whitelist
const expertList = ['/expert/review']
router.beforeEach(async(to, from, next) => {
  const token = getToken('access')
  const fromPath = from.path
  if (token) {
    let tokenType = token.split('-')[0]
    if (to.path === '/login' && tokenType === 'N') {
      next({ path: '/' })
    } else if (to.path === '/expert/login' && tokenType === 'E') {

    } else {
      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      if (expertList.indexOf(fromPath) !== -1) {
        next(`/expert/login`)
      }
      next(`/login`)
    }
  }

})

router.afterEach(() => {

})
